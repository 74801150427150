<template>
  <div style="color: #000; height: 100%">
    <van-nav-bar :title="isok == 'true' ? name + ' 的销售业绩' : '我的销售业绩'" left-arrow :fixed="true" :z-index="999" @click-left="back">
      <template #right>
        <span class="youshang" @click="toteam" v-if="isok != 'true'">客户和团队</span>
        <span class="youshang" @click="tomyteam" v-else>我的客户</span>
      </template>
    </van-nav-bar>
    <div class="topmian">
      <div class="topmian_a">
        <div class="w_50" style="color: #0d906e; border-right: 1px solid #dcdcdc">
          <div class="leiji">累计总业绩(元)</div>
          <div class="leijiner">
            {{
              form.totalAchievement ? form.totalAchievement.toFixed(2) : "0.00"
            }}
          </div>
        </div>
        <div class="w_50">
          <div class="leiji">累计总收益(元)</div>
          <div class="leijiner">
            {{ form.totalAmount ? form.totalAmount.toFixed(2) : "0.00" }}
          </div>
        </div>
      </div>
      <van-row class="shouyi">
        <van-col span="5">
          <div>本月业绩</div>
          <div style="color: #0d906e">
            {{
              form.monthAchievement ? form.monthAchievement.toFixed(2) : "0.00"
            }}
          </div>
        </van-col>
        <van-col span="5">
          <div>本月收益</div>
          <div style="color: #f58220">
            {{ form.monthAmount ? form.monthAmount.toFixed(2) : "0.00" }}
          </div>
        </van-col>
        <van-col span="6" v-if="isok != 'true'">
          <div>未审核余额</div>
          <div style="color: #2a5caa">
            <span>{{ form.balance ? form.balance.toFixed(2) : "0.00" }}</span>
          </div>
        </van-col>
        <van-col span="8" v-else class="citicheng" @click="toticheng">
          <div v-if="idisok != 'true'">
            <div>提成比例</div>
            <div style="color: #2a5caa; position: relative">
              <span>{{ form.atokerRate }}%</span>,
              <span>{{ form.tokerRate }}%</span>
              <img class="ticimg" align="absmiddle" src="../../assets/newimg/tichen.png" alt />
            </div>
          </div>
          <div v-else>
            <div>未审核余额</div>
            <div style="color: #2a5caa">
              <span>{{ form.balance ? form.balance.toFixed(2) : "0.00" }}</span>
            </div>
          </div>
        </van-col>
        <van-col span="6">
          <div>已审核余额</div>
          <!-- v-if="isok != 'true'" -->
          <div style="color: #2a5caa">
            {{ form.useBalance ? form.useBalance.toFixed(2) : "0.00" }}
            <!-- {{form.useBalance?form.useBalance.toFixed(2):'0.00'}} -->
          </div>
          <!-- <div style="color: #2a5caa;" v-else>****</div> -->
        </van-col>
      </van-row>
      <van-row class="annius" v-if="isok != 'true'">
        <van-col span="12">
          <van-button round class="tianniu" type="warning" @click="tojilu">提现记录</van-button>
        </van-col>
        <van-col span="12">
          <van-button round class="tianniu" type="warning" color="#f26522" @click="totixian">申请提现</van-button>
        </van-col>
      </van-row>
      <van-row class="lis_a lis_a_tou" style="border-top: 15px solid #f5f5f5">
        <van-col span="5" @click="actv = 0">
          <div>收益列表</div>
          <div class="heng" v-if="actv == 0"></div>
        </van-col>
        <van-col span="5" @click="actv = 1">
          <div>业绩列表</div>
          <div class="heng" v-if="actv == 1"></div>
        </van-col>
        <van-col span="14" class="fixssd"  @click="toticheng" v-if="boss == 'true'">
          <div>提成比例</div>
          <div class="tifix">
            <span>{{ form.atokerRate }}%,</span>
            <span>{{ form.tokerRate }}%</span>
            <img class="tisimg" align="absmiddle" src="../../assets/newimg/tichen.png" alt />
          </div>
        </van-col>
      </van-row>
      <van-row class="lis_a">
        <van-col span="17">
          <span style="vertical-align: middle" @click="toshow(1)">{{
            time
          }}</span>
          <van-icon style="vertical-align: middle" name="arrow-down" /> 一
          <span @click="toshow(2)" style="vertical-align: middle">{{
            timeb
          }}</span>
          <van-icon style="vertical-align: middle" name="arrow-down" />
        </van-col>
        <van-col span="7" class="ris er">
          <span style="vertical-align: middle" v-if="actv == 0">￥{{ monthAmount ? monthAmount.toFixed(2) : "0.00" }}</span>
          <span style="vertical-align: middle" v-if="actv == 1">￥{{ form.tokerAchievementDTOS[0] ? (form.tokerAchievementDTOS[0].myAmount+form.tokerAchievementDTOS[0].otherAmount).toFixed(2) : "0.00" }}</span>
        </van-col>
      </van-row>
    </div>

    <div class :class="isok != 'true' ? 'mian' : 'mian_a'" v-if="actv == 0">
      <div class="nian">
        <div class="lis_b" v-for="(item, idx) in form.sellManLogVOS" :key="idx" @click="toxiangqin(item)">
          <van-row class="list">
            <van-col span="16">商品名称：{{ item.goodName }}</van-col>
            <van-col span="8" class="ris zonger">+{{ item.redPrice ? item.redPrice.toFixed(2) : "0.00" }}</van-col>
            <van-col span="14" class="list_a">
              <span style="vertical-align: middle">订单总额：{{
                  item.orderPrice ? item.orderPrice.toFixed(2) : "0.00"
                }}</span>
              <img class="pinimg" v-if="item.levelType == 1" src="../../assets/newimg/p_h.png" alt />
              <img class="pinimg" v-else-if="item.levelType == 2" src="../../assets/newimg/p_z.png" alt />
              <img class="pinimg" v-else-if="item.levelType == 3" src="../../assets/newimg/p_x.png" alt />
              <img class="pinimg" v-else src="../../assets/newimg/dai.png" alt />
            </van-col>
            <van-col span="10" class="list_a ris">
              <span style="vertical-align: middle">{{ item.buyDate }}</span>
            </van-col>
            <van-col span="14" class="list_b">
              <span class="vam">用户微信：</span>
              <img class="ims vam" :src="item.wxImg" alt />
              <span class="vam">{{ item.wxName }}</span>
              <div class="right cengji" @click.stop="tocheng(item)">
                {{ item.layerCounts }}
              </div>
            </van-col>
            <van-col span="10" class="list_b ris">
              <span class="vam">绑定车牌：{{ item.carNo }}</span>
            </van-col>
          </van-row>
        </div>
      </div>
    </div>
    <div class :class="isok != 'true' ? 'mian' : 'mian_a'" v-else>
      <van-row class="list_2">
        <van-col span="2">#</van-col>
        <van-col span="7">成员姓名</van-col>
        <van-col span="5" class="cent">本人业绩</van-col>
        <van-col span="5" class="cent">成员业绩</van-col>
        <van-col span="5" class="cent">团队业绩</van-col>
      </van-row>
      <van-row class="list_2 list_2_b" v-for="(item, is) in form.tokerAchievementDTOS" :key="is">
        <van-col span="2">{{ is + 1 }}</van-col>
        <van-col span="7">{{ item.name }}</van-col>
        <van-col span="5" class="cent">{{ item.myAmount }}</van-col>
        <van-col span="5" class="cent">{{ item.otherAmount }}</van-col>
        <van-col span="5" class="cent">{{
          item.myAmount + item.otherAmount
        }}</van-col>
      </van-row>
    </div>

    <van-popup v-model="show" position="bottom" :style="{ height: '315px' }">
      <van-datetime-picker v-model="currentDate" @confirm="toshijian" @cancel="show = false" type="year-month" :title="sjtype==1?'选择起始年月':'选择截止年月'" :min-date="minDate" :max-date="maxDate" :formatter="formatter" />
    </van-popup>
    <van-popup closeable v-model="showtc" class="bilit">
      <div class="ti_h1">设置</div>
      <div class="ti_h2" style="color: #4169e1;">非事故车提成</div>
      <div class="ti_h2">
        <span class="letname">第1年：</span>
        <input class="imps" v-model="tcform.rate" type="number" />
        <span>%</span>
      </div>
      <div class="ti_h2">
        <span class="letname">第2年：</span>
        <input class="imps" v-model="tcform.secondYear" type="number" />
        <span>%</span>
      </div>
      <div class="ti_h2">
        <span class="letname">第3年及以后：</span>
        <input class="imps" v-model="tcform.thirdYear" type="number" />
        <span>%</span>
      </div>
      <div class="ti_h2" style="color: #4169e1;">事故车提成</div>
      <div class="ti_h2">
        <span class="letname">第1年：</span>
        <input class="imps" v-model="tcform.accidentRate" type="number" />
        <span>%</span>
      </div>
      <div class="ti_h2">
        <span class="letname">第2年：</span>
        <input class="imps" v-model="tcform.asecondYear" type="number" />
        <span>%</span>
      </div>
      <div class="ti_h2">
        <span class="letname">第3年及以后：</span>
        <input class="imps" v-model="tcform.athirdYear" type="number" />
        <span>%</span>
      </div>
      <div class="ti_h3">
        <!-- <span ></span> -->
        <van-checkbox v-model="form.qrAuth" shape="square">授权团队二维码</van-checkbox>
      </div>

      <van-button plain class="tibott" type="primary" size="small" @click="toxiugaitc">确定</van-button>
    </van-popup>
    <van-popup closeable v-model="cenjishow" class="chenjitan">
      <div class="tancena">客户层级</div>
      <div class="wos">
        <span>我—></span>
        <span v-if="cenform.oneName">{{ cenform.oneName }}—></span>
        <span v-if="cenform.twoName">{{ cenform.twoName }}—></span>
        <span v-if="cenform.threeName">{{ cenform.threeName }}—></span>
        <span>{{ cenform.wxName }}</span>
      </div>
    </van-popup>
  </div>
</template>

<script>
import { sellManincome, incomemonth, updaterate } from "../../api/check.js";
import utils from "../../assets/utils";
export default {
  data() {
    return {
      form: {},
      userInfo: JSON.parse(localStorage.getItem("userInfo")),
      shangjia: JSON.parse(sessionStorage.getItem("shangjia")),
      minDate: new Date(2020, 0, 1),
      maxDate: new Date(2030, 10, 1),
      currentDate: new Date(),
      tcform:{},
      cenjishow: false,
      isok: this.$route.query.isok,
      idisok: this.$route.query.idisok,
      ljisok: this.$route.query.ljisok,
      number: 0,
      uid: this.$route.query.uid,
      name: this.$route.query.name,
      gid: this.$route.query.gid,
      boss: this.$route.query.boss,
      showtc: false,
      checked: false,
      radio: "1",
      show: false,
      atokerRate: "",
      time: "",
      timeb: "",
      monthAmount: "",
      actv: 0,
      loading: false,
      billNumbe: "",
      cenform: {},
      oid: "",
      totalMoney: "",
      shopname: "",
      id: 0,
      monthDate: "",
      sjtype: 0,
      endDate: "",
      downloadData: {},
    };
  },
  methods: {
    tocheng(item) {
      this.cenform = item;
      this.cenjishow = true;
    },
    back() {
      this.$router.go(-1);
    },
    toticheng() {
      this.tcform ={
        rate:this.form.tokerRate,
        accidentRate:this.form.atokerRate,
        secondYear:this.form.secondYear,
        asecondYear:this.form.asecondYear,
        athirdYear:this.form.athirdYear,
        thirdYear:this.form.thirdYear,
      }
      // this.number = this.form.tokerRate;
      // this.atokerRate = this.form.atokerRate;
      this.showtc = true;
    },
    toxiugaitc() {
      var data = {
        gid: this.gid,
        uid: this.uid,
        sid: this.userInfo.id,
        qrAuth: this.form.qrAuth,
        ...this.tcform
        // rate: this.number,
        // accidentRate: this.atokerRate,
      };
      updaterate(data)
        .then((e) => {
          if (e.code == 200) {
            this.$toast.success("修改成功");
            this.onLoad();
            this.showtc = false;
          }
        })
        .catch(() => loading.clear());
    },
    tojilu() {
      this.$router.push({
        path: "My_record",
        // query: {
        //   balance: this.form.balance
        // }
      });
    },
    toxiangqin(item) {
      if (item.type == 0) {
        location.href =
          "http://customer.car-posthouse.cn/introduce_bonuses/index_detail.html?gid=" +
          item.gid +
          "&oid=" +
          item.oid +
          "&isok=" +
          "true";
      }
    },
    totixian() {
      this.$router.push({
        path: "My_withdraw",
        query: {
          balance: this.form.useBalance,
        },
      });
    },
    toteam() {
      this.$router.push({
        path: "My_team",
        query: {},
      });
    },
    tomyteam() {
      this.$router.push({
        name: "My_secondTow",
        query: {
          uid: this.uid,
          name: this.name,
          gid: this.gid,
          ljisok: this.ljisok,
        },
      });
    },
    toshow(type) {
      this.show = true;
      this.sjtype = type;
    },
    toshijian(e) {
      var timeValue = this.timeFormat(e, 1);

      if (this.sjtype == 1) {
        this.time = this.timeFormat(e, 2);
        this.monthDate = timeValue;
      } else {
        this.timeb = this.timeFormat(e, 2);
        this.endDate = timeValue;
      }

      console.log(this.endDate);
      this.totimes(timeValue);
      this.show = false;
    },
    formatter(type, val) {
      if (type === "year") {
        return `${val}年`;
      } else if (type === "month") {
        return `${val}月`;
      }
      return val;
    },
    timeFormat(time, type) {
      // 时间格式化 2019-09-08
      let year = time.getFullYear();
      let month = time.getMonth() + 1;
      let day = time.getDate();
      var months = month < 10 ? "0" + month : month;
      if (type == 1) {
        return year + "-" + months;
      } else {
        return year + "年" + months + "月";
      }
    },
    totimes(timeValue) {
      const loading = this.$toast.loading({
        mask: true,
        duration: 0,
        message: "加载中...",
      });
      var uid = "";
      if (this.isok == "true") {
        uid = this.uid;
      } else {
        uid = this.userInfo.id;
      }
      // uid: uid
      incomemonth({
        monthDate: this.monthDate,
        endDate: this.endDate,
        uid: uid,
      })
        .then((e) => {
          loading.clear();
          this.form.sellManLogVOS = e.data.sellManLogVOS;
          this.form.tokerAchievementDTOS = e.data.tokerAchievementDTOS;
          this.monthAmount = e.data.monthAmount;
        })
        .catch(() => loading.clear());
    },
    onLoad() {
      // const loading = this.$toast.loading({
      //   mask: true,
      //   duration: 0,
      //   message: "加载中..."
      // });
      var uid = "";
      if (this.isok == "true") {
        uid = this.uid;
      } else {
        uid = this.userInfo.id;
      }
      sellManincome({ uid: uid })
        .then((e) => {
          // loading.clear();
          this.form = e.data;
          this.monthAmount = e.data.monthAmount;
          var nian = e.data.monthDate.split("-");
          console.log(nian);
          this.time = nian[0] + "年" + nian[1] + "月";
          this.timeb = nian[0] + "年" + nian[1] + "月";
          this.monthDate = nian[0] + "-" + nian[1];
          this.endDate = nian[0] + "-" + nian[1];
        })
        .catch(() => loading.clear());
    },
  },
  created() {
    this.onLoad();
  },
  mounted() {},
};
</script>

<style lang="less" scoped>
.topmian {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  margin-top: 46px;
  // padding-bottom: 20px;

  background: #fff;
  z-index: 6;
}
.topmian_a {
  margin: 20px 15px 0 15px;
  font-size: 16px;
  text-align: center;
  color: #f58220;
  padding-bottom: 20px;
  display: flex;
  border-bottom: 1px solid #dcdcdc;
}
.w_50 {
  width: 50%;
}
.leiji {
  line-height: 25px;
  margin: 0 0 10px 0;
  // margin-top: 5px;
}
.leijiner {
  font-weight: 500;
  font-size: 34px;
  line-height: 30px;
}
.shouyi {
  margin: 0 15px;
  padding-top: 12px;
  padding-bottom: 12px;
  font-size: 14px;
  text-align: center;
}
.annius {
  // padding-top: 12px;
  margin: 0 15px;
  font-size: 26px;
  text-align: center;
  margin-bottom: 20px;
}
.tianniu {
  width: 85%;
}
.mian {
  padding: 385px 12px 0 12px;
}
.mian_a {
  padding: 330px 12px 0 12px;
}
.nian {
  overflow: hidden;
}
.lis_a {
  // border-top: 15px solid #f5f5f5;
  font-size: 16px;
  line-height: 40px;
  padding: 0 12px;
  border-bottom: 1px solid #f2f3f4;
  // margin-top: 20px;
}
.lis_a_tou {
  font-weight: bold;
  line-height: 35px;
  text-align: center;
}
.heng {
  height: 2px;
  background: #666;
  margin-bottom: 4px;
}
.lis_b {
  font-size: 14px;
  border-bottom: 1px solid #f2f3f4;
}
.er {
  color: red;
  font-weight: bold;
}
.zonger {
  color: #f58220;
}
.ris {
  text-align: right;
}
.list {
  padding: 10px 0;
  color: #333;
}
.list_a {
  color: #666;
  font-size: 12px;
}
.list_2 {
  font-size: 14px;
  line-height: 35px;
}
.list_2_b .van-col {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  font-size: 13px;
  color: #333;
}
.list_b {
  font-size: 13px;
}
.vam {
  vertical-align: middle;
}
.youshang {
  color: #4169e1;
}
.ims {
  width: 16px;
  border-radius: 8px;
}
.ticimg {
  position: absolute;
  width: 14px;
  right: 0;
  top: 0;
  margin-top: 5px;
  margin-right: 10px;
}
.bilit {
  width: 260px;
  // height: 200px;

  font-size: 14px;
  text-align: center;
  padding: 0 25px;
  padding-bottom: 25px;
  .letname{
    display: inline-block;
    width: 100px;
    text-align: right;
  }
}
.ti_h1 {
  line-height: 40px;
  padding-top: 8px;
  font-size: 16px;
}
.ti_h2 {
  margin-top: 10px;
  text-align: left;
}
.ti_h3 {
  margin: auto;
  margin-top: 15px;
  //  text-align: left;
}
.van-checkbox {
  // display: inline-block;
}
.van-radio {
  margin-top: 5px;
}
.imps {
  width: 70px;
  border-top: none;
  border-left: none;
  border-right: none;
  text-align: center;
}
.tibott {
  width: 90px;
  margin-top: 25px;
}
.cengji {
  font-size: 12px;
  color: #4169e1;
  border: 1px solid #4169e1;
  line-height: 15px;
  height: 16px;
  width: 16px;
  text-align: center;
  margin-top: 5px;
}
.cent {
  text-align: center;
}
.wos {
  margin-top: 20px;
  font-size: 14px;
}
.chenjitan {
  width: 260px;
  text-align: center;
  font-size: 16px;
  height: 140px;
}
.tancena {
  padding: 14px 0 10px 0;
}
.pinimg {
  width: 15px;
  vertical-align: middle;
  margin-left: 10px;
}
.fixssd {
  display: flex;
  align-items: center;

  justify-content: right;
  .tifix {
      font-weight: normal;
      margin-left: 5px;
    display: flex;
    align-items: center;
    .tisimg{
      width: 16px;
    }
  }
}
</style>